import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectArticle } from '../../../../labels/collections/components/FormInputs';
import { Select } from '../../../../videos/components/FormInputs';
import ScheduleContentInput, {
  getFormState,
  getInitialScheduleState,
  getServerState,
  useScheduleContentChange
} from '../../../../videos/components/ScheduleContentInput';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import { ModuleFields, Permissions, ShowFields, Switch } from './FormInputs';

const AUTO_GENERATED_SORT_BY_TYPES = {
  RECENT: 'RECENT',
  RANDOM: 'RANDOM',
  READS: 'READS'
};

const ARTICLE_GENERATED_TYPE = {
  CONTRIBUTOR: 'CONTRIBUTOR',
  COLLECTION: 'COLLECTION',
  TAG: 'TAG',
  TOPIC: 'TOPIC'
};
const CONFIG_TITLE = {
  [MODULE_TYPES.ARTICLE_LIST]: 'Article List',
  [MODULE_TYPES.ARTICLE_LIST_V2]: 'Article List V2',
  [MODULE_TYPES.ARTICLE_CAROUSEL]: 'Article Carousel',
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: 'Article Carousel V2',
  [MODULE_TYPES.FEATURED_ARTICLE]: 'Featured Article',
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: 'Featured Article V2'
};

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [MODULE_TYPES.ARTICLE_CAROUSEL, MODULE_TYPES.ARTICLE_LIST]
  },
  {
    name: 'articleAuthor',
    label: 'Authors',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleTopic',
    label: 'Topics',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleTag',
    label: 'Tags',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleSave',
    label: 'Article Save',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleComment',
    label: 'Comment',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleShare',
    label: 'Share',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articlePrayer',
    label: 'Article Prayer',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2,
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.ARTICLE_CAROUSEL,
      MODULE_TYPES.ARTICLE_CAROUSEL_V2,
      MODULE_TYPES.ARTICLE_LIST,
      MODULE_TYPES.ARTICLE_LIST_V2
    ]
  },
  {
    name: 'articleDate',
    label: 'Article Date',
    allowedTypes: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'relatedContent',
    label: 'Related Content',
    allowedTypes: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  },
  {
    name: 'articleHtml',
    label: 'Article Html',
    allowedTypes: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]
  }
];
const AUTO_GENERATED_SORT_BY_TYPES_OPTIONS = [
  { label: 'Recent', value: AUTO_GENERATED_SORT_BY_TYPES.RECENT },
  { label: 'Random', value: AUTO_GENERATED_SORT_BY_TYPES.RANDOM },
  { label: 'Reads', value: AUTO_GENERATED_SORT_BY_TYPES.READS }
];

const getInitialValues = (type) => ({
  title: '',
  description: '',
  permissions: [],
  status: STATUS_TYPES.PUBLISHED,
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    articleAuthor: true,
    articleComment: true,
    articleShare: true,
    articlePrayer: true,
    articleTimestamp: true,
    articleTopic: true,
    articleDate: true,
    relatedContent: true,
    articleSave: true,
    articleHtml: true,
    articleTag: true,
    viewAll: true
  },
  config: {
    autoGenerate: false,
    autoGenerateById: '',
    autoGenerateByType: ARTICLE_GENERATED_TYPE[0],
    autoGenerateSortBy: AUTO_GENERATED_SORT_BY_TYPES[0],
    articles: [
      MODULE_TYPES.FEATURED_ARTICLE,
      MODULE_TYPES.FEATURED_ARTICLE_V2
    ]?.includes(type)
      ? { id: '', url: '', title: '' }
      : []
  },
  ...([MODULE_TYPES.FEATURED_ARTICLE, MODULE_TYPES.ARTICLE_CAROUSEL]?.includes(
    type
  ) && {
    ...getInitialScheduleState()
  })
});

const { ARTICLE } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.ARTICLE_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_ARTICLE]: [ARTICLE, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: [ARTICLE, ...REST_PAGE_TYPES]
};

const MODULE_KEYS = {
  [MODULE_TYPES.ARTICLE_LIST]: 'articleListModule',
  [MODULE_TYPES.ARTICLE_LIST_V2]: 'articleListModuleV2',
  [MODULE_TYPES.ARTICLE_CAROUSEL]: 'articleCarouselModule',
  [MODULE_TYPES.ARTICLE_CAROUSEL_V2]: 'articleCarouselModuleV2',
  [MODULE_TYPES.FEATURED_ARTICLE]: 'featuredArticleModule',
  [MODULE_TYPES.FEATURED_ARTICLE_V2]: 'featuredArticleModuleV2'
};

function ArticleForm({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );
  const isMultiArticle = ![
    MODULE_TYPES.FEATURED_ARTICLE,
    MODULE_TYPES.FEATURED_ARTICLE_V2
  ]?.includes(type);
  const { autoGenerateByType, autoGenerate } =
    Form.useWatch(['config'], form) ?? {};
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const { setInitialData, checkIfDataChanged } = useScheduleContentChange();

  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;

      if (
        [
          MODULE_TYPES.FEATURED_ARTICLE,
          MODULE_TYPES.ARTICLE_CAROUSEL
        ]?.includes(type)
      ) {
        setInitialData(defaultValues?.moduleData?.schedule);
      }

      const values = {
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        config: {
          autoGenerate:
            defaultValues?.moduleData?.config?.autoGenerate ?? false,
          autoGenerateByType:
            defaultValues?.moduleData?.config?.autoGenerateByType || null,
          autoGenerateById: idm
            ? TEMPLATE_OPTION
            : getAutoGenerateByDataValue[
                defaultValues?.moduleData?.config?.autoGenerateByType
              ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
              null,
          autoGenerateSortBy:
            defaultValues?.moduleData?.config?.articleAutoGenerateSortBy ||
            null,
          autoGenerateLimit:
            defaultValues?.moduleData?.config?.autoGenerateLimit || null,
          articles: [
            MODULE_TYPES.FEATURED_ARTICLE,
            MODULE_TYPES.FEATURED_ARTICLE_V2
          ]?.includes(type)
            ? {
                id: defaultValues?.moduleData?.config?.article?.id,
                title: defaultValues?.moduleData?.config?.article?.title,
                url:
                  defaultValues?.moduleData?.config?.article?.imageThumbnail
                    ?.url
              }
            : defaultValues?.moduleData?.config?.articles?.map(
                ({ id, title, imageThumbnail }) => ({
                  id,
                  title,
                  url: imageThumbnail?.url ?? ''
                })
              )
        },
        ...([
          MODULE_TYPES.FEATURED_ARTICLE,
          MODULE_TYPES.ARTICLE_CAROUSEL
        ]?.includes(type) && {
          ...getFormState(defaultValues?.moduleData)
        })
      };
      form.setFieldsValue(values);
    }
  }, [form, moduleId, formType, defaultValues, form, initialValues, type]);

  const handleSubmit = (values) => {
    const {
      config,
      settings,
      permissions,
      isDefaultModule: isDefaultModule$,
      schedule,
      ...rest
    } = values ?? {};
    const key = MODULE_KEYS[type];

    const {
      articles,
      autoGenerate: articleAutoGenerate,
      autoGenerateById = '',
      autoGenerateLimit,
      ...restConfig
    } = config ?? {};

    const isDefault = allowedTemplateConfig && isDefaultModule$;

    if (!key) return;
    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        config: {
          ...restConfig,
          ...(isDefault
            ? {
                articleId: '*'
              }
            : {
                ...([
                  MODULE_TYPES.FEATURED_ARTICLE,
                  MODULE_TYPES.FEATURED_ARTICLE_V2
                ]?.includes(type)
                  ? {
                      articleId: articles?.id
                    }
                  : {
                      articles:
                        articles?.map(({ id }, i) => ({
                          articleId: id,
                          order: i + 1
                        })) ?? []
                    })
              }),
          autoGenerate: articleAutoGenerate,
          ...(autoGenerateLimit && {
            autoGenerateLimit: Number(autoGenerateLimit)
          }),
          autoGenerateById: isEdit
            ? autoGenerateById?.value
            : autoGenerateById?.key ?? null
        },
        ...([
          MODULE_TYPES.FEATURED_ARTICLE,
          MODULE_TYPES.ARTICLE_CAROUSEL
        ]?.includes(type) && {
          ...getServerState(schedule),
          ...(isEdit && {
            isScheduleUpdated: checkIfDataChanged(
              getServerState(schedule)?.schedule
            )
          })
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <ModuleFields />
        <Space className="w-full" direction="vertical">
          <Space className="w-full" direction="vertical">
            <ShowFields settings={SETTINGS} type={type} />
            {allowedTemplateConfig && pageType === ARTICLE && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}
            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <Form.Item
                  className="m-0"
                  name={['config', 'autoGenerate']}
                  valuePropName="checked"
                >
                  <Switch label="Auto Generate" />
                </Form.Item>
                {autoGenerate && (
                  <>
                    <Form.Item
                      label="Auto Generated By Type"
                      name={['config', 'autoGenerateByType']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_OPTIONS}
                        placeholder="Select type"
                        onChange={() => {
                          form.setFieldValue(
                            ['config', 'autoGenerateById'],
                            null
                          );
                        }}
                      />
                    </Form.Item>
                    {autoGenerateByType && autoGenerateByIdProps && (
                      <Form.Item
                        label="Auto Generated By"
                        name={['config', 'autoGenerateById']}
                      >
                        <Select
                          placeholder="Select"
                          query={autoGenerateByIdProps?.query}
                          variablesSelector={({ skip, limit, search }) => ({
                            filter: {
                              skip,
                              limit,
                              search
                            },
                            ...(autoGenerateByType ===
                              AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                              where: { isActive: true }
                            })
                          })}
                          dataSelector={autoGenerateByIdProps?.dataSelector}
                          keys={autoGenerateByIdProps?.keys}
                          {...(allowedTemplateConfig && {
                            includeOptions
                          })}
                        />
                      </Form.Item>
                    )}
                    <Form.Item
                      label="Auto Generated Sort By"
                      name={['config', 'autoGenerateSortBy']}
                    >
                      <AntdSelect
                        options={AUTO_GENERATED_SORT_BY_TYPES_OPTIONS}
                        placeholder="Select sort by"
                      />
                    </Form.Item>
                    {isMultiArticle && (
                      <Form.Item
                        label="No. of Auto generated Articles"
                        name={['config', 'autoGenerateLimit']}
                        rules={[
                          formValidatorRules?.number,
                          formValidatorRules?.maxNumberAllowed(
                            configData?.MAX_AUTO_GENERATE_LIMIT?.value || 20
                          )
                        ]}
                      >
                        <Input placeholder="Enter number" />
                      </Form.Item>
                    )}
                  </>
                )}
                {!autoGenerate && (
                  <>
                    <Form.Item
                      label={
                        isMultiArticle ? 'Select Articles' : 'Select Article'
                      }
                      name={['config', 'articles']}
                      extra={
                        isMultiArticle ? 'Select items in order you want' : ''
                      }
                    >
                      <SelectArticle multiple={isMultiArticle} />
                    </Form.Item>
                  </>
                )}
                {[
                  MODULE_TYPES.FEATURED_ARTICLE,
                  MODULE_TYPES.ARTICLE_CAROUSEL
                ]?.includes(type) && (
                  <ScheduleContentInput
                    form={form}
                    checkIfDataChanged={checkIfDataChanged}
                    isEdit={isEdit}
                    fullWidth
                  />
                )}
              </>
            )}
            <Permissions />
            <div className="d-flex button-section">
              <Space>
                <Form.Item>
                  <Button
                    type="text"
                    htmlType="submit"
                    className="text-btn mr-8"
                    size="middle"
                    disabled={loading}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    disabled={loading}
                    onClick={onCancel}
                    type="text"
                    className="text-btn2"
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Space>
        </Space>
      </Form>
    </>
  );
}

export default ArticleForm;
