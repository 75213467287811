import { useMutation } from '@apollo/client';
import {
  Select as AntdSelect,
  Button,
  Form,
  Input,
  Space,
  Typography
} from 'antd';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import React, { useEffect, useMemo } from 'react';
import {
  AUTO_GENERATED_OPTIONS,
  AUTO_GENERATED_SORT_BY_OPTIONS,
  AUTO_GENERATED_TYPES,
  MODULE_TYPES,
  PAGE_TYPES,
  STATIC_DATA_KEYS,
  STATUS_TYPES,
  TEMPLATE_OPTION,
  getAutoGenerateByDataValue,
  getAutoGeneratedByIdProps
} from '../../../../../common/constants';
import { formValidatorRules, timeToString } from '../../../../../common/utils';
import useStaticData from '../../../../../hooks/useStaticData';
import { SelectVideos } from '../../../../labels/collections/components/FormInputs';
import { Select } from '../../../../videos/components/FormInputs';
import ScheduleContentInput, {
  getFormState,
  getInitialScheduleState,
  getServerState,
  useScheduleContentChange
} from '../../../../videos/components/ScheduleContentInput';
import { FORM_TYPES } from '../../../context/EditPageProvider';
import {
  CREATE_PAGE_MODULE,
  UPDATE_PAGE_MODULE
} from '../../../graphql/Mutations';
import {
  DateTimePicker,
  ModuleFields,
  Permissions,
  ShowFields,
  Switch
} from './FormInputs';

dayjs?.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const getInitialValues = (type) => ({
  title: '',
  description: '',
  status: STATUS_TYPES.PUBLISHED,
  permissions: [],
  isDefaultModule: false,
  settings: {
    title: true,
    description: true,
    viewAll: true,
    videoTimestamp: true,
    videoDate: true,
    videoSpeaker: true,
    videoTopic: true,
    videoTag: true,
    videoOrder: true,
    videoProgressBar: true,
    separator: true,
    searchFilter: true,
    tagsFilter: true,
    videoDescription: true,
    carouselIndicator: true,
    videoCTA: true,
    collectionNames: true,
    primaryAction: true,
    timer: true,
    videoSave: true
  },
  config: {
    autoGenerate: false,
    autoGenerateByType: null,
    autoGenerateById: null,
    autoGenerateSortBy: null,
    autoGenerateLimit: null,
    videos: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ].includes(type)
      ? { id: '', url: '', title: '' }
      : [],
    timer: { endDate: null, startDate: null, timezone: moment.tz.guess() }
  },
  ...([
    MODULE_TYPES.FEATURED_VIDEO,
    MODULE_TYPES.FEATURED_VIDEO_SLIDER,
    MODULE_TYPES.VIDEO_CAROUSEL
  ]?.includes(type) && {
    ...getInitialScheduleState()
  })
});

const SETTINGS = [
  {
    name: 'title',
    label: 'Title',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'description',
    label: 'Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'viewAll',
    label: 'View All Button',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'videoDescription',
    label: 'Video Description',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ]
  },
  {
    name: 'searchFilter',
    label: 'Search Filter',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST, MODULE_TYPES.VIDEO_GRID]
  },
  {
    name: 'tagsFilter',
    label: 'Tags Filter',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST, MODULE_TYPES.VIDEO_GRID]
  },
  {
    name: 'videoTimestamp',
    label: 'Timestamp',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ]
  },
  {
    name: 'videoDate',
    label: 'Date',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ]
  },
  {
    name: 'videoSpeaker',
    label: 'Speaker',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ]
  },
  {
    name: 'collectionNames',
    label: 'Collection Names',
    allowedTypes: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'videoTopic',
    label: 'Topic',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ]
  },
  {
    name: 'videoTag',
    label: 'Tags',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER
    ]
  },
  {
    name: 'videoOrder',
    label: 'Order in Collection',
    allowedTypes: [
      MODULE_TYPES.VIDEO_PLAYER,
      MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
      MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL,
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID,
      MODULE_TYPES.VIDEO_PLAYER_V2,
      MODULE_TYPES.LIVE_VIDEO_PLAYER,
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'videoProgressBar',
    label: 'Progress Bar',
    allowedTypes: [
      MODULE_TYPES.VIDEO_CAROUSEL,
      MODULE_TYPES.VIDEO_LIST,
      MODULE_TYPES.VIDEO_GRID
    ]
  },
  {
    name: 'separator',
    label: 'Separator',
    allowedTypes: [MODULE_TYPES.VIDEO_LIST]
  },
  {
    name: 'carouselIndicator',
    label: 'Carousel Indicator',
    allowedTypes: [MODULE_TYPES.FEATURED_VIDEO_SLIDER]
  },
  {
    name: 'videoCTA',
    label: 'Video CTA',
    allowedTypes: [
      MODULE_TYPES.FEATURED_VIDEO,
      MODULE_TYPES.FEATURED_VIDEO_SLIDER
    ]
  },
  {
    name: 'primaryAction',
    label: 'CTA Button',
    allowedTypes: [MODULE_TYPES.LIVE_VIDEO_PLAYER]
  },
  {
    name: 'timer',
    label: 'Timer (Livestream with Pending status)',
    allowedTypes: [MODULE_TYPES.LIVE_VIDEO_PLAYER]
  },
  {
    name: 'videoSave',
    label: 'Save Button',
    allowedTypes: [MODULE_TYPES.FEATURED_VIDEO]
  }
];

const TIMEZONE_OPTIONS = moment.tz.names()?.map((tz) => ({
  label: tz,
  value: tz
}));

const MODULE_KEYS = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'videoContinueWatchingCarouselModule',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'videoWatchLaterCarouselModule',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'videoCarouselModule',
  [MODULE_TYPES.VIDEO_LIST]: 'videoListModule',
  [MODULE_TYPES.VIDEO_GRID]: 'videoGridModule',
  [MODULE_TYPES.FEATURED_VIDEO]: 'featuredVideoModule',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'featuredVideoSliderModule',
  [MODULE_TYPES.VIDEO_PLAYER]: 'videoPlayerModule',
  [MODULE_TYPES.VIDEO_PLAYER_V2]: 'videoPlayerModuleV2',
  [MODULE_TYPES.LIVE_VIDEO_PLAYER]: 'liveVideoPlayerModule'
};

const CONFIG_TITLE = {
  [MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL]:
    'Video Continue Watching Configs',
  [MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL]: 'Video Watch Later Configs',
  [MODULE_TYPES.VIDEO_CAROUSEL]: 'Video Carousel Configs',
  [MODULE_TYPES.VIDEO_LIST]: 'Video List Configs',
  [MODULE_TYPES.VIDEO_GRID]: 'Video Grid Configs',
  [MODULE_TYPES.FEATURED_VIDEO]: 'Feature Video Configs',
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: 'Feature Video Slider Configs',
  [MODULE_TYPES.VIDEO_PLAYER]: 'Video Player Configs',
  [MODULE_TYPES.VIDEO_PLAYER_V2]: 'Video Player V2 Configs',
  [MODULE_TYPES.LIVE_VIDEO_PLAYER]: 'Live Video Player Configs'
};

const { VIDEO } = PAGE_TYPES;
const REST_PAGE_TYPES = [
  PAGE_TYPES.TOPIC,
  PAGE_TYPES.TAG,
  PAGE_TYPES.CONTRIBUTOR,
  PAGE_TYPES.COLLECTION
];

const ALLOWED_TEMPLATE_CONFIGS = {
  [MODULE_TYPES.VIDEO_CAROUSEL]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_LIST]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_GRID]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO]: [VIDEO, ...REST_PAGE_TYPES],
  [MODULE_TYPES.FEATURED_VIDEO_SLIDER]: [...REST_PAGE_TYPES],
  [MODULE_TYPES.VIDEO_PLAYER]: [VIDEO],
  [MODULE_TYPES.VIDEO_PLAYER_V2]: [VIDEO],
  [MODULE_TYPES.LIVE_VIDEO_PLAYER]: [VIDEO]
};

const VideoForm = ({
  form: { type: formType, moduleId, defaultValues, index: order },
  pageId,
  type,
  onCancel,
  onSuccess,
  isDefaultPage,
  pageType
}) => {
  const [form] = Form.useForm();
  const isEdit = formType === FORM_TYPES.EDIT;
  const initialValues = useMemo(() => getInitialValues(type), [type]);
  const allowedTemplateConfig =
    isDefaultPage && ALLOWED_TEMPLATE_CONFIGS[type]?.includes(pageType);
  const isMultiVideo = ![
    MODULE_TYPES.FEATURED_VIDEO,
    MODULE_TYPES.VIDEO_PLAYER,
    MODULE_TYPES.VIDEO_PLAYER_V2,
    MODULE_TYPES.LIVE_VIDEO_PLAYER
  ].includes(type);

  const isLiveVideo = type === MODULE_TYPES.LIVE_VIDEO_PLAYER;
  const { setInitialData, checkIfDataChanged } = useScheduleContentChange();

  const { data: configData } = useStaticData(STATIC_DATA_KEYS.CONFIGS);

  const [addEditModule, { loading }] = useMutation(
    isEdit ? UPDATE_PAGE_MODULE : CREATE_PAGE_MODULE
  );

  const configProps = Form.useWatch(['config'], form);
  const isDefaultModule = Form.useWatch(['isDefaultModule'], form);
  const { autoGenerateByType, autoGenerate } =
    configProps ?? initialValues.config;

  const includeOptions = useMemo(
    () => (autoGenerateByType === pageType ? [TEMPLATE_OPTION] : []),
    [autoGenerateByType, pageType]
  );

  useEffect(() => {
    if (moduleId && defaultValues && formType === FORM_TYPES.EDIT && form) {
      const idm = defaultValues?.isDefaultModule;

      if (
        [
          MODULE_TYPES.FEATURED_VIDEO,
          MODULE_TYPES.FEATURED_VIDEO_SLIDER,
          MODULE_TYPES.VIDEO_CAROUSEL
        ]?.includes(type)
      ) {
        setInitialData(defaultValues?.moduleData?.schedule);
      }

      form.setFieldsValue({
        title: defaultValues?.title ?? '',
        description: defaultValues?.description ?? '',
        status: defaultValues?.status ?? STATUS_TYPES.DRAFT,
        isDefaultModule: idm,
        permissions:
          defaultValues?.permissions?.map((value) => ({
            label: value,
            value
          })) ?? [],
        settings: {
          ...initialValues.settings,
          ...defaultValues?.moduleData?.settings
        },
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            ...(isLiveVideo && {
              primaryAction: {
                url:
                  defaultValues?.moduleData?.config?.primaryAction?.url ?? '',
                title:
                  defaultValues?.moduleData?.config?.primaryAction?.title ?? ''
              },
              timer: {
                startDate: defaultValues?.moduleData?.config?.timer?.startDate
                  ? dayjs(defaultValues?.moduleData?.config?.timer?.startDate)
                  : null,
                endDate: defaultValues?.moduleData?.config?.timer?.endDate
                  ? dayjs(defaultValues?.moduleData?.config?.timer?.endDate)
                  : null,
                timezone:
                  defaultValues?.moduleData?.config?.timer?.timezone ??
                  moment.tz.guess()
              }
            }),
            ...(![
              MODULE_TYPES.VIDEO_PLAYER_V2,
              MODULE_TYPES.VIDEO_PLAYER,
              MODULE_TYPES.LIVE_VIDEO_PLAYER
            ]?.includes(type) && {
              autoGenerate:
                defaultValues?.moduleData?.config?.autoGenerate ?? false,
              autoGenerateByType:
                defaultValues?.moduleData?.config?.autoGenerateByType || null,
              autoGenerateById: idm
                ? TEMPLATE_OPTION
                : getAutoGenerateByDataValue[
                    defaultValues?.moduleData?.config?.autoGenerateByType
                  ]?.(defaultValues?.moduleData?.config?.autoGenerateByData) ??
                  null,
              autoGenerateSortBy:
                defaultValues?.moduleData?.config?.videoAutoGenerateSortBy ||
                null,
              autoGenerateLimit:
                defaultValues?.moduleData?.config?.autoGenerateLimit || null
            }),
            videos: isMultiVideo
              ? defaultValues?.moduleData?.config?.videos?.map(
                  ({ id, title, imageThumbnail, isLive }) => ({
                    id,
                    title,
                    url: imageThumbnail?.url ?? '',
                    isLive
                  })
                ) || []
              : {
                  id: defaultValues?.moduleData?.config?.video?.id ?? '',
                  title: defaultValues?.moduleData?.config?.video?.title ?? '',
                  url:
                    defaultValues?.moduleData?.config?.video?.imageThumbnail
                      ?.url ?? '',
                  isLive: defaultValues?.moduleData?.config?.video?.isLive
                }
          },
          ...([
            MODULE_TYPES.FEATURED_VIDEO,
            MODULE_TYPES.FEATURED_VIDEO_SLIDER,
            MODULE_TYPES.VIDEO_CAROUSEL
          ]?.includes(type) && {
            ...getFormState(defaultValues?.moduleData)
          })
        })
      });
    }
  }, [
    form,
    moduleId,
    formType,
    defaultValues,
    form,
    initialValues,
    isMultiVideo,
    isLiveVideo
  ]);

  const handleSubmit = ({
    config,
    settings,
    permissions,
    isDefaultModule: isDefaultModule$,
    schedule,
    ...rest
  }) => {
    const key = MODULE_KEYS[type];
    if (!key) return;

    const isDefault = allowedTemplateConfig && isDefaultModule$;

    const {
      videos,
      // eslint-disable-next-line no-shadow
      autoGenerate,
      autoGenerateById,
      autoGenerateLimit,
      timer,
      ...restConfig
    } = config ?? {};

    const payload = {
      ...(!isEdit && {
        type,
        order: order + 1
      }),
      permissions: permissions?.map(({ value }) => value),
      ...rest,
      [key]: {
        settings,
        ...(![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && {
          config: {
            autoGenerate,
            autoGenerateById: autoGenerateById?.value,
            ...restConfig,
            ...(isDefault
              ? {
                  videoId: '*'
                }
              : {
                  ...(!autoGenerate &&
                    isMultiVideo && {
                      videos:
                        videos?.map(({ id }, i) => ({
                          videoId: id,
                          order: i + 1
                        })) ?? []
                    }),
                  ...(!autoGenerate &&
                    !isMultiVideo && {
                      videoId: videos?.id ?? ''
                    })
                }),
            ...(autoGenerateLimit && {
              autoGenerateLimit: Number(autoGenerateLimit)
            }),
            ...((timer?.startDate || timer?.endDate || timer?.timezone) && {
              timer: {
                ...(timer?.startDate && {
                  startDate: timeToString(timer?.startDate)
                }),
                ...(timer?.endDate && {
                  endDate: timeToString(timer?.endDate)
                }),
                ...(timer?.timezone && {
                  timezone: timer?.timezone ?? moment.tz.guess()
                })
              }
            })
          }
        }),
        ...([
          MODULE_TYPES.FEATURED_VIDEO,
          MODULE_TYPES.FEATURED_VIDEO_SLIDER,
          MODULE_TYPES.VIDEO_CAROUSEL
        ]?.includes(type) && {
          ...getServerState(schedule),
          ...(isEdit && {
            isScheduleUpdated: checkIfDataChanged(
              getServerState(schedule)?.schedule
            )
          })
        })
      }
    };

    addEditModule({
      variables: { data: payload, id: isEdit ? moduleId : pageId }
    }).then(
      ({
        data: {
          addUpdatedPageModule: { pageModule }
        }
      }) => {
        onSuccess(pageModule);
      }
    );
  };

  const autoGenerateByIdProps = getAutoGeneratedByIdProps[autoGenerateByType];

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <ModuleFields />
      <Space className="w-full" direction="vertical">
        <ShowFields settings={SETTINGS} type={type} />
        {![
          MODULE_TYPES.VIDEO_CONTINUE_WATCHING_CAROUSEL,
          MODULE_TYPES.VIDEO_WATCH_LATER_CAROUSEL
        ].includes(type) && (
          <Space className="w-full" direction="vertical">
            {allowedTemplateConfig && pageType === VIDEO && (
              <>
                <Typography.Text>Template Config</Typography.Text>
                <Form.Item
                  className="m-0"
                  name="isDefaultModule"
                  valuePropName="checked"
                >
                  <Switch label="Use Template Data" />
                </Form.Item>
              </>
            )}

            {(!allowedTemplateConfig ||
              (allowedTemplateConfig && !isDefaultModule)) && (
              <>
                <Typography.Text>{CONFIG_TITLE[type]}</Typography.Text>
                <div>
                  {![
                    MODULE_TYPES.VIDEO_PLAYER,
                    MODULE_TYPES?.VIDEO_PLAYER_V2,
                    MODULE_TYPES.LIVE_VIDEO_PLAYER
                  ]?.includes(type) && (
                    <>
                      <Form.Item
                        name={['config', 'autoGenerate']}
                        valuePropName="checked"
                      >
                        <Switch
                          label={
                            isMultiVideo
                              ? 'Auto Generated Videos'
                              : 'Auto Generated Video'
                          }
                        />
                      </Form.Item>
                      {autoGenerate && (
                        <>
                          <Form.Item
                            label="Auto Generated By Type"
                            name={['config', 'autoGenerateByType']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_OPTIONS}
                              placeholder="Select type"
                              onChange={() => {
                                form.setFieldValue(
                                  ['config', 'autoGenerateById'],
                                  null
                                );
                              }}
                            />
                          </Form.Item>
                          {autoGenerateByType && autoGenerateByIdProps && (
                            <Form.Item
                              label="Auto Generated By"
                              name={['config', 'autoGenerateById']}
                            >
                              <Select
                                placeholder="Select"
                                query={autoGenerateByIdProps?.query}
                                variablesSelector={(filter) => ({
                                  filter,
                                  ...(autoGenerateByType ===
                                    AUTO_GENERATED_TYPES.CONTRIBUTOR && {
                                    where: { isActive: true }
                                  })
                                })}
                                dataSelector={
                                  autoGenerateByIdProps?.dataSelector
                                }
                                keys={autoGenerateByIdProps?.keys}
                                {...(allowedTemplateConfig && {
                                  includeOptions
                                })}
                              />
                            </Form.Item>
                          )}
                          <Form.Item
                            label="Auto Generated Sort By"
                            name={['config', 'autoGenerateSortBy']}
                          >
                            <AntdSelect
                              options={AUTO_GENERATED_SORT_BY_OPTIONS}
                              placeholder="Select sort by"
                            />
                          </Form.Item>
                          {isMultiVideo && (
                            <Form.Item
                              label="No. of Auto generated Videos"
                              name={['config', 'autoGenerateLimit']}
                              rules={[
                                formValidatorRules?.number,
                                formValidatorRules?.maxNumberAllowed(
                                  configData?.MAX_AUTO_GENERATE_LIMIT?.value ||
                                    20
                                )
                              ]}
                            >
                              <Input placeholder="Enter number" />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {!autoGenerate && (
                    <>
                      <Form.Item
                        label={isMultiVideo ? 'Select Videos' : 'Select Video'}
                        name={['config', 'videos']}
                        extra={
                          isMultiVideo ? 'Select items in order you want' : ''
                        }
                      >
                        <SelectVideos
                          multiple={isMultiVideo}
                          {...(isLiveVideo && { isLive: true })}
                        />
                      </Form.Item>
                    </>
                  )}
                  {[
                    MODULE_TYPES.FEATURED_VIDEO,
                    MODULE_TYPES.FEATURED_VIDEO_SLIDER,
                    MODULE_TYPES?.VIDEO_CAROUSEL
                  ]?.includes(type) && (
                    <ScheduleContentInput
                      form={form}
                      checkIfDataChanged={checkIfDataChanged}
                      isEdit={isEdit}
                      fullWidth
                    />
                  )}
                </div>
              </>
            )}
          </Space>
        )}
        {isLiveVideo && (
          <div>
            <Form.Item
              label="CTA Button Title"
              name={['config', 'primaryAction', 'title']}
            >
              <Input placeholder="Enter CTA Button Title" />
            </Form.Item>
            <Form.Item
              label="CTA Hyperlink"
              name={['config', 'primaryAction', 'url']}
            >
              <Input placeholder="Enter CTA Hyperlink" />
            </Form.Item>
            <Form.Item
              label="Live stream start Date/Time"
              name={['config', 'timer', 'startDate']}
            >
              <DateTimePicker
                placeholder="Enter as Central Time"
                disabledDate={(current) => {
                  const chicagoTime = moment()?.tz('America/Chicago');
                  return (
                    current &&
                    current?.tz('America/Chicago', true)?.startOf('day') <
                      chicagoTime?.startOf('day')
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              label="Live stream end Date/Time"
              name={['config', 'timer', 'endDate']}
            >
              <DateTimePicker
                placeholder="Enter as Central Time"
                disabledDate={(current) => {
                  const chicagoTime = moment()?.tz('America/Chicago');
                  return (
                    current &&
                    current?.tz('America/Chicago', true)?.startOf('day') <
                      chicagoTime?.startOf('day')
                  );
                }}
              />
            </Form.Item>
            <Form.Item label="Timezone" name={['config', 'timer', 'timezone']}>
              <AntdSelect showSearch options={TIMEZONE_OPTIONS} />
            </Form.Item>
          </div>
        )}
        <Permissions />
        <div className="d-flex button-section">
          <Space>
            <Form.Item>
              <Button
                type="text"
                htmlType="submit"
                className="text-btn mr-8"
                size="middle"
                disabled={loading}
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                disabled={loading}
                onClick={onCancel}
                type="text"
                className="text-btn2"
              >
                Cancel
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Space>
    </Form>
  );
};

export default VideoForm;
